import { BreakpointsEnum } from '@/landing/types';
import { COLORS, TYPOGRAPHY } from '@landing/constants';
import { Button } from '@landing/ui/components';
import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  onConfirm?: () => void;
}

const SuccessModalContent: FC<Props> = ({ onConfirm }) => {
  return (
    <Root>
      <Subtitle>
        В ближайшее время мы свяжемся с вами для обсуждения деталей
      </Subtitle>
      <StyledButton onClick={onConfirm}>Хорошо</StyledButton>
    </Root>
  );
};

export default SuccessModalContent;

const Root = styled.div`
  padding-top: 8px;
`;

const Subtitle = styled.p`
  color: ${COLORS.grayscale600};
  ${TYPOGRAPHY.bodyRegular16};
  margin-bottom: 32px;

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: 14px;
    line-height: 1.5;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  padding: 12px;
  ${TYPOGRAPHY.bodyMedium16};
  border-radius: 12px;

  @media (max-width: ${BreakpointsEnum.mb}px) {
    font-size: 16px;
    line-height: 1.5;
  }
`;
